<template>
    <div class="relative">
        <div class="absolute right-0 pointer-events-none">
            <svg
                class="md:h-[540px] md:w-auto h-auto w-full"
                viewBox="0 0 477 540"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M586.448 58.9055C586.448 120.709 537.236 170.427 477 170.427C416.764 170.427 367.552 120.709 367.552 58.9055C367.552 -2.89807 416.764 -52.6162 477 -52.6162C537.236 -52.6162 586.448 -2.89807 586.448 58.9055Z"
                    stroke="#FFD61E"
                    stroke-width="18" />
                <path
                    d="M682.477 58.9055C682.477 173.713 590.292 266.436 477 266.436C363.708 266.436 271.523 173.713 271.523 58.9055C271.523 -55.9017 363.708 -148.626 477 -148.626C590.292 -148.626 682.477 -55.9017 682.477 58.9055Z"
                    stroke="#FFD61E"
                    stroke-opacity="0.74"
                    stroke-width="30" />
                <path
                    d="M799.682 58.9055C799.682 239.695 654.917 385.714 477 385.714C299.083 385.714 154.318 239.695 154.318 58.9055C154.318 -121.884 299.083 -267.903 477 -267.903C654.917 -267.903 799.682 -121.884 799.682 58.9055Z"
                    stroke="#FFD61E"
                    stroke-opacity="0.5"
                    stroke-width="36" />
                <path
                    d="M914.372 57.8577C914.372 302.666 718.146 500.38 477 500.38C235.854 500.38 39.6277 302.666 39.6277 57.8577C39.6277 -186.952 235.854 -384.666 477 -384.666C718.146 -384.666 914.372 -186.952 914.372 57.8577Z"
                    stroke="#FFD61E"
                    stroke-opacity="0.25"
                    stroke-width="54" />
            </svg>
        </div>
    </div>
</template>

<script setup>

</script>
